.Popup {
  width: 400px;
  margin-top: 10px;
  position: absolute;
  background-color: var(--secondary-background);
  z-index: 2;
  padding: 20px 20px 25px 20px;
  border-radius: 10px;
  border: 2px var(--primary-shadow);
  border-style: solid;
  color: var(--primary-text);
  box-shadow: 5px 5px;
}
.popup-container {
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.popup-icon {
  cursor: pointer;
}
.popup-title {
  font-size: 20px;
  font-weight: 500;
}
.popup-link {
  font-weight: 500;
}
@media (max-width: 768px) {
  .Popup {
    width: 270px;
  }
}
