.Navbar {
  background-color: var(--yellow);
  padding: 10px 20px;
  color: var(--primary-text);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
}

.navbar-actions {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
}

.navbar-app-name {
  font-size: 20px;
  font-weight: 600;
}

.navbar-actions-home,
.navbar-actions-lang img,
.navbar-actions-about,
.navbar-actions-privacy,
.navbar-actions-contact,
.navbar-actions-sponsor {
  margin-right: 15px;
}

@media (max-width: 768px) {
  .Navbar {
    padding: 5px 8px;
  }

  .navbar-app-name {
    font-size: 12px;
  }

  .navbar-actions-home,
  .navbar-actions-about,
  .navbar-actions-sponsor,
  .navbar-actions-contact,
  .navbar-actions-privacy {
    font-size: 10px;
  }

  .navbar-actions-lang img {
    width: 15px;
  }
}