@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary-background: #e9ddd4;
  --secondary-background: #fff;
  --secondary-text: #9f8da9;
  --primary-text: #320545;
  --yellow: #ffdd00;
  --primary-shadow: #0a0c3e;
}

#root {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

body {
  background-color: var(--secondary-background);
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  padding: 50px;
}

.title {
  text-align: center;
  color: var(--primary-text);
  font-weight: 600;
  font-size: 40px;
  margin: 20px 0;
}

.button {
  width: 150px;
  height: 30px;
  font-weight: 600;
  background-color: var(--yellow);
  color: var(--primary-text);
  border: none;
  margin: 15px;
  padding: 5px 15px;
  border-radius: 25px;
  border: 1px solid;
  box-shadow: 5px 5px var(--primary-shadow);
}

.button:hover {
  background-color: var(--secondary-text);
  cursor: pointer;
  color: var(--yellow);
}

.input-space {
  border-radius: 5px;
  width: 350px;
  height: 100px;
  padding: 10px 15px;
}

.App {
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: radial-gradient(var(--primary-shadow) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  width: 100%;
}

h2 {
  color: var(--primary-text);
  margin-bottom: 5px;
}

.result {
  color: var(--primary-text);
  font-size: 33px;
  background-color: var(--yellow);
  margin: 10px;
}

@media (max-width: 768px) {
  .container {
    padding: 20px 10px;
  }
  .main {
    padding: 10px;
  }
  .title {
    font-size: 35px;
  }
  .input-space {
    width: 250px;
  }
}
